body {
    padding: 0;
    font-family: "GothamNarrowBook", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style:normal;
    font-size: 14   px;
    line-height: 1.5em;
    background: #DBDCDD;
    color: #000;
    -ms-overflow-style: scrollbar; /*Fix IE scroll bar issue*/
}

h1, h2, h3, h4, h5, h6, .breadcrumb, .nav-header, .nav-header-2, .nav-header-3 {
    line-height: 1.13em;
    text-align:center;
    font-family:"GothamBold", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-top: 10px;
    margin-top:0;
    margin-bottom: 20px;
}
h1 { font-size: 2em; }
h2,
.nav-header-2 { 
    font-size: 1.75em; 
    line-height: 1.25em;
}
h3,
.nav-header-3 { 
    font-size: 1.25em; 
    line-height: 1.3em; 
}
h4 {     
    font-size: 1em;
    line-height: 1.38em; 
}
h5 { 
    font-size: 0.88em;
    line-height: 1.42em; 
}
h6 { line-height: 1.34em; }
.eyebrow { text-align: center; }
.small {
    font-size: 0.88em;
    line-height: 1.42em;
}
.eyebrow .small,
h1 .small, 
h2 .small, 
h3 .small { 
    font-size: .32em; 
    line-height: 2.5em; 
    color: inherit;
}
.eyebrow .small { 
    font-size: 1.1em;
    line-height: 1.6em;
}
a, .arrow-link.arrow-color { color: #0066B2; }
a:hover, a:focus, a:active { 
    text-decoration: underline;
    color: #0066B2;
}    

/* Small, Med, and Large devices (tablets, 768px and up) */
@media (min-width: 768px) {
    h1 .small, 
    h2 .small, 
    h3 .small { 
        font-size: .48em; 
    }
    .eyebrow .small { font-size: 1.35em; }
        
    .col { padding-left: 0; padding-right: 0; }
}