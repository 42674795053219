.bg-odd {
  background-color: $contrast-color-2;
}
.d-flex {
  display: flex !important;
}
.d-block {
  display: block !important;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.flex-column {
  flex-direction: column;
}
.flex-end {
  justify-content: flex-end !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.w-100 {
  width: 100%;
}
.flex-wrap {
  flex-wrap: wrap;
}

.highlight {
  color: #0066b2 !important;
}
.light-grey {
  color: grey;
}

.subtitle {
  font-size: 18px !important;
}

.detailed-description {
  font-size: 12px;
  font-style: italic;
  color: rgb(150, 150, 150);
}
