.p-datatable {
  td,
  th {
    &.shading {
      background-color: $contrast-color-2 !important;
    }

    small {
      margin-top: 12px;
      line-height: 90% !important;
    }
    span {
      line-height: 1.5em !important;
      color: black;
    }
  }
  td {
    vertical-align: top;
  }
  tr {
    &.selected {
      background-color: #e7f9ff;
      font-weight: 700 !important;
      font-size: 14px !important;
    }
  }
}

.chevron-table.no-spacing {
  table {
    border-collapse: collapse !important;
    tbody {
      tr {
        td {
          &:first-child {
            border-left-width: 0px !important;
          }
          &:last-child {
            border-right-width: 0px !important;
          }
        }
      }
    }
  }
}
